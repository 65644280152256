// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-index-page-js": () => import("/opt/build/repo/src/templates/news-index-page.js" /* webpackChunkName: "component---src-templates-news-index-page-js" */),
  "component---src-templates-news-article-page-js": () => import("/opt/build/repo/src/templates/news-article-page.js" /* webpackChunkName: "component---src-templates-news-article-page-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-guest-js": () => import("/opt/build/repo/src/pages/faq/guest.js" /* webpackChunkName: "component---src-pages-faq-guest-js" */),
  "component---src-pages-faq-host-js": () => import("/opt/build/repo/src/pages/faq/host.js" /* webpackChunkName: "component---src-pages-faq-host-js" */),
  "component---src-pages-guest-js": () => import("/opt/build/repo/src/pages/guest.js" /* webpackChunkName: "component---src-pages-guest-js" */),
  "component---src-pages-host-js": () => import("/opt/build/repo/src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("/opt/build/repo/src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

